import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  siteInfo = [];
  groupTimes = [];
  groupInfo: any;
  users = [];

  // selected
  siteIndex: number;
  groupIndex: number;
  groupPostInfo: any;

  // pop-up
  isSelectedUser: boolean;
  testerInfo = [];
  groupTime: string;
  testerName: string;
  strat: string;
  user: string;
  coOpCost: number;
  noCoOpCost: boolean;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {

  }

  selectSite(value: string, index: number) {
    this.siteIndex = index;

    const info = {
      ScheduleStamp: new Date(),
      SiteID: value
    };

    this.http.post('home/GetStudies', info).subscribe((r: any) => {
      this.groupTimes = r;

      for (let group of this.groupTimes) {
        const hour = group.groupTime.substring(11, 13);
        const mins = group.groupTime.substring(14, 16);

        group.groupTimeJ = (hour > 12 ? hour - 12 : hour) + ':' + mins + (hour >= 12 ? 'PM' : 'AM');
      }
    });
  }

  selectGroup(group: any, index: number) {
    this.groupIndex = index;
    this.groupTime = group.groupTimeJ;

    this.groupPostInfo = {
      GroupID: group.groupID,
      ScheduleStamp: group.groupTime
    };

    this.http.post('home/GetGroupInfo', this.groupPostInfo).subscribe((r: any) => {
      this.groupInfo = r[0];

      if (this.groupInfo.coOpCost == null || this.groupInfo.coOpCost === '') {
        this.http.post('home/GetStatusesStrat', group.groupID, this.httpOptions).subscribe((s: any) => {
          this.user = s;
        });
      } else {
        this.noCoOpCost = true;
      }
    });
  }

  selectUser(user: any) {
    if (user.queStatusID === '20' || user.queStatusID === '21') {
      this.isSelectedUser = true;
      this.testerName = user.firstName + ' ' + user.lastName;
      this.coOpCost = this.groupInfo.coOpCost;
      this.user = user;

      const info = {
        testerID: user.testerID,
        studyNo: this.groupInfo.studyNo,
        groupID: user.groupID,
        strat: user.strat
      };

      this.http.post('home/getQRData', info).subscribe((r: any) => {
        // this.qrData = r.grKey.toUpperCase() + ',' + r.trKey.toUpperCase();
        this.testerInfo = r.qRData.testerInfo;
        this.strat = r.strat;
      });
    }
  }

  closeWindow(event: boolean) {
    this.isSelectedUser = event;
    this.noCoOpCost = event;

    this.http.post('home/GetGroupInfo', this.groupPostInfo).subscribe((r: any) => {
      this.groupInfo = r[0];

      this.http.post('home/GetStatusesStrat', this.groupPostInfo.GroupID, this.httpOptions).subscribe((s: any) => {
        this.users = s;
      });
    });
  }

  ngOnInit() {
    this.siteInfo = [{
      name: 'Chicago, IL',
      value: '00000'
    }, {
      name: 'Plano, TX',
      value: '00002'
    }, {
      name: 'Santa Ana, CA',
      value: '00017'
    }, {
      name: 'White Plains, NY',
      value: '00105'
    }];
  }
}
