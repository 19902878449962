import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.css']
})
export class PopUpComponent implements OnInit {
  @Output() closeWindow = new EventEmitter < boolean > ();

  constructor() {}

  close() {
    this.closeWindow.emit(false);
  }

  ngOnInit(): void {
  }
}
