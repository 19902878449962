import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: any;
  username: string;
  pin: string;

  constructor(private http: HttpClient, private router: Router) {
    this.loginForm = new FormGroup({
      logonID: new FormControl(this.username, [
        Validators.required,
        Validators.minLength(1)
      ]),
      pin: new FormControl(this.pin, [
        Validators.required,
        Validators.minLength(1)
      ])
    });
  }

  onSubmit(value: any) {
    this.http.post('account/UserLogon', value).subscribe((r: any) => {
      if (r.status === 'Succeed') {
        // move to main page
        this.router.navigateByUrl('/home');
      }
    });
  }

  ngOnInit(): void {}

}
