import {
  HttpClient
} from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-user-detail-info',
  templateUrl: './user-detail-info.component.html',
  styleUrls: ['./user-detail-info.component.css']
})
export class UserDetailInfoComponent implements OnInit, OnChanges {
  address: string;
  email: string;
  phone: string;
  queStatus: string;
  strats = [];

  // confirm pop-up
  isConfirm: boolean;
  process: string;

  @Input() testerInfo: string;
  @Input() testerName: string;
  @Input() groupTime: string;
  @Input() strat: string;
  @Input() user: any;
  @Input() coOpCost: number;
  @Output() closeWindow = new EventEmitter < boolean > ();

  constructor(private http: HttpClient) {}

  close() {
    this.closeWindow.emit(false);
  }

  signInBtn() {
    this.process = 'CheckIn';
    this.isConfirm = true;
  }

  payBtn() {
    this.process = 'Pay';
    this.isConfirm = true;
  }

  cancel() {
    this.process = 'Cancelled';
    this.isConfirm = true;
  }

  noShow() {
    this.process = 'No Show';
    this.isConfirm = true;
  }

  proceed() {
    let queStatusID = '';

    if (this.process === 'No Show') {
      queStatusID = '24';

      const info = {
        testerID: this.user.testerID,
        groupID: this.user.groupID,
        queStatusID: queStatusID,
        paid: this.coOpCost
      };

      this.http.post('/home/UpdatePayQue', info).subscribe();
    } else if (this.process === 'CheckIn') {
      const info = {
        testerID: this.user.testerID,
        queStatusID: this.user.queStatusID,
        groupID: this.user.groupID
      };

      this.http.post('/home/SignIn', info).subscribe();
    } else if (this.process === 'Pay') {
      const info = {
        testerID: this.user.testerID,
        groupID: this.user.groupID,
        queStatusID: '25',
        paid: this.coOpCost
      };

      this.http.post('/home/UpdatePayQue', info).subscribe();
    } else {
      queStatusID = '23';

      const info = {
        testerID: this.user.testerID,
        groupID: this.user.groupID,
        queStatusID: queStatusID,
        paid: this.coOpCost
      };

      this.http.post('/home/UpdatePayQue', info).subscribe();
    }
  }

  ngOnChanges(): void {
    this.address = this.testerInfo[1];
    this.email = this.testerInfo[2];
    this.phone = '(' + this.testerInfo[3].substring(0, 3) + ')' + this.testerInfo[3].substring(3, 6) + '-' + this.testerInfo[3].substring(6, this.testerInfo[3].length);
    console.log(this.phone);

    this.queStatus = this.user.queStatusID;

    this.strats = this.strat.split(',');

    for (let i = 0; i < this.strats.length; i++) {
      this.strats[i] = this.strats[i].split('|');
    }
  }

  ngOnInit(): void {
    this.address = this.testerInfo[1];
    this.email = this.testerInfo[2];
    this.phone = '(' + this.testerInfo[3].substring(0, 3) + ')' + this.testerInfo[3].substring(3, 6) + '-' + this.testerInfo[3].substring(6, this.testerInfo[3].length);
  }
}
